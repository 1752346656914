<template>
  <b-modal
    v-model="showModal"
    hide-header
    hide-footer
    size="lg"
    dialog-class="custom-modal"
  >
    <!-- Modal Header -->
    <div class="header">
      <div>
        <p>{{ modalHeader | capitalize}} </p> <br>
        <span class="sub">{{subtitle | capitalize}}</span>
      </div>
      <span class="material-symbols-rounded" @click="hideModal">close</span>
    </div>
    
    <!-- Modal Body -->
    <div class="body mt-3">
      <div class="content" v-html="viewData"></div>
    </div>
    
    <!-- Modal Footer -->
    <div class="footer">
      <b-button class="download-button" @click="downloadDocument">
        <span class="material-symbols-rounded download-icon">download</span>
        Download
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    modalHeader: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    viewData: {
      type: String,
      required: true,
    },
    selectedIndex: Number,
    selectedType: String,
  },
  methods: {
    hideModal() {
      this.$emit("update:showModal", false);
    },
    downloadDocument() {
      this.$emit('download', this.selectedIndex, this.selectedType);
    }
  },
};
</script>

<style scoped>

/* Custom Modal Styles */
.custom-modal {
  max-width: 864px;
  height: 694px;
  border-radius: 8px;
}

.header {
  width: 100%;
  height: 82px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.header p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
}

.sub {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px;
  color: var(--dark-grey);
  margin-top: 4px;
}


.header span {
  cursor: pointer;
  font-size: 24px;
}

.body {
  width: 100%;
  max-height: 560px;
  padding: 12px 24px;
  background-color: var(--interface-grey);
  overflow-y: auto;
}
.footer {
  width: 100%;
  height: 52px;
  padding: 18px 16px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
}

.download-button {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 32px;
  padding: 4px 16px;
  border-radius: 4px;
  background-color: var(--primary);
  color: #ffffff;
}

.download-icon {
  margin-right: 8px;
}

</style>
