<template>
  <b-modal :id="modalId" hide-footer hide-header>
    <div class="header mb-4">
      <p>{{ title }}</p>
      <span class="material-symbols-rounded" @click="$bvModal.hide(modalId)">close</span>
    </div>
    <div class="custom-list-container">
      <div
        class="custom-list-item mb-3"
        v-for="(member, index) in members"
        :key="index"
      >
      <span class="material-symbols-rounded person">person</span>
      <span class="member-name">{{ member }}</span>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.modal-content{
    width:460px;
    height:460px;
    border-radius: 8px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 16px 24px 16px 24px;
  border-bottom: 1px solid #e9ecef;
}

.header p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  display: inline;
}

.header span {
  cursor: pointer;
}

.custom-list-item {
    display: flex; /* Ensures items are in a row */
    align-items: center; /* Vertically aligns the icon and text */
    gap: 4px;
    width: 412px;
    height: 44px;
    border-radius: 8px;
    padding: 10px 12px;
    background: var(--interface-grey);
    border: none;
}
.custom-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.person {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: var(--mid-grey);
    border-radius: 50%;
    color: var(--light-grey);
    font-variation-settings: 'FILL' 1;
    font-size: 20px;
}

.member-name {
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
    color: var(--neutral-black);
}
</style>
